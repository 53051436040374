import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles.css';
import ChildrenBookApp from './App';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <ChildrenBookApp />
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}