// src/data/booksData.js
export const booksData = [
  {
    id: 1,
    title: "安娜的冒险",
    cover: "/images/book_cover.png",
    category: "冒险",
    pages: [
      { image: "/images/show_picture.png", text: "从前，有一个叫安娜的小女孩，她总是梦想着去冒险。" },
      { image: "/images/show_picture.png", text: "有一天，她听说附近的山洞里藏着一个神秘的宝藏。" },
      { image: "/images/show_picture.png", text: "安娜决定去探险，她收拾好背包，带上了手电筒和小地图。" },
      { image: "/images/show_picture.png", text: "当她来到山洞前时，心里既兴奋又害怕。" },
      { image: "/images/show_picture.png", text: "安娜站在洞穴入口，内心充满了紧张和恐惧。尽管已经走了这么远，她还是忍不住想要转身离开。" },
      { image: "/images/show_picture.png", text: "就在这时，一位智慧的老人突然出现，给了她一些建议。他告诉安娜，勇气并不是没有恐惧，而是面对恐惧并克服它的能力。" },
      { image: "/images/show_picture.png", text: "安娜深吸一口气，鼓起勇气走进了洞穴。" }
    ]
  },
  {
    id: 2,
    title: "小熊的蜂蜜树",
    cover: "/images/book_cover.png",
    category: "自然",
    pages: [
      { image: "/images/show_picture.png", text: "森林里住着一只爱吃蜂蜜的小熊。" },
      { image: "/images/show_picture.png", text: "有一天，它发现了一棵神奇的蜂蜜树。" },
      { image: "/images/show_picture.png", text: "但是树太高了，小熊够不着。" },
      { image: "/images/show_picture.png", text: "小熊想出了一个聪明的办法。" },
      { image: "/images/show_picture.png", text: "最后，小熊终于吃到了美味的蜂蜜。" }
    ]
  },
  // 可以继续添加更多绘本数据
];