// src/components/BookCard.js
import React from 'react';
import { motion } from 'framer-motion';

const BookCard = ({ book, onSelect }) => (
  <motion.div
    className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={() => onSelect(book)}
  >
    <img src={book.cover} alt={book.title} className="w-full h-40 object-cover" />
    <div className="p-2">
      <h3 className="text-sm font-semibold text-gray-800 truncate">{book.title}</h3>
      <p className="text-xs text-gray-600">{book.category}</p>
    </div>
  </motion.div>
);

export default BookCard;