// src/components/HomePage.js
import React, { useState } from 'react';
import { Home, Search } from 'lucide-react';
import BookCard from './BookCard';
import { booksData } from '../data/booksData';

const HomePage = ({ onSelectBook, bookmarks }) => {
  const [category, setCategory] = useState("全部");
  const categories = ["全部", "冒险", "自然"];

  const filteredBooks = category === "全部"
    ? booksData
    : booksData.filter(book => book.category === category);

  return (
    <div className="flex flex-col h-full">
      <header className="bg-orange-300 text-white p-4 flex justify-between items-center rounded-b-2xl shadow-md">
        <Home className="w-6 h-6" />
        <h1 className="text-xl font-bold">小小图书馆</h1>
        <Search className="w-6 h-6" />
      </header>

      <div className="flex space-x-2 p-2 bg-orange-100">
        {categories.map(cat => (
          <button
            key={cat}
            className={`px-3 py-1 rounded-full text-sm ${category === cat ? 'bg-orange-500 text-white' : 'bg-white text-orange-500'}`}
            onClick={() => setCategory(cat)}
          >
            {cat}
          </button>
        ))}
      </div>

      <main className="flex-grow p-4 overflow-y-auto">
        <h2 className="text-lg font-semibold mb-2">继续阅读</h2>
        <div className="grid grid-cols-2 gap-4 mb-6">
          {bookmarks.map(bookmark => (
            <BookCard key={bookmark.id} book={bookmark} onSelect={onSelectBook} />
          ))}
        </div>

        <h2 className="text-lg font-semibold mb-2">所有绘本</h2>
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
          {filteredBooks.map(book => (
            <BookCard key={book.id} book={book} onSelect={onSelectBook} />
          ))}
        </div>
      </main>
    </div>
  );
};

export default HomePage;