// src/App.js
import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import HomePage from './components/HomePage';
import BookReader from './components/BookReader';

const ChildrenBookApp = () => {
  const [selectedBook, setSelectedBook] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);

  const handleBookmark = (book, page) => {
    const newBookmark = { ...book, lastReadPage: page };
    setBookmarks(prev => [...prev.filter(b => b.id !== book.id), newBookmark]);
  };

  return (
    <div className="h-screen bg-amber-50">
      <AnimatePresence mode="wait">
        {selectedBook ? (
          <motion.div
            key="reader"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
            className="h-full"
          >
            <BookReader
              book={selectedBook}
              onBack={() => setSelectedBook(null)}
              onBookmark={handleBookmark}
            />
          </motion.div>
        ) : (
          <motion.div
            key="home"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
            className="h-full"
          >
            <HomePage
              onSelectBook={setSelectedBook}
              bookmarks={bookmarks}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ChildrenBookApp;