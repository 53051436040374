// src/components/BookReader.js
import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, BookmarkPlus } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const BookReader = ({ book, onBack, onBookmark }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => setCurrentPage(prev => Math.min(prev + 1, book.pages.length - 1));
  const prevPage = () => setCurrentPage(prev => Math.max(0, prev - 1));

  return (
    <div className="flex flex-col h-full">
      <header className="bg-orange-300 text-white p-4 flex justify-between items-center rounded-b-2xl shadow-md z-10">
        <button onClick={onBack} className="text-white">
          <ChevronLeft className="w-6 h-6" />
        </button>
        <h1 className="text-xl font-bold">{book.title}</h1>
        <button onClick={() => onBookmark(book, currentPage)} className="text-white">
          <BookmarkPlus className="w-6 h-6" />
        </button>
      </header>

      <main className="flex-grow relative overflow-hidden p-4">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentPage}
            initial={{ opacity: 0, x: 300 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -300 }}
            transition={{ duration: 0.3 }}
            className="w-full h-full"
          >
            <div className="w-full h-2/3 bg-teal-600 rounded-2xl shadow-lg overflow-hidden mb-4">
              <img
                src={book.pages[currentPage].image}
                alt={`Page ${currentPage + 1}`}
                className="w-full h-full object-cover"
              />
            </div>

            <div className="bg-white p-4 rounded-2xl shadow-md">
              <p className="text-center text-sm text-gray-700 leading-relaxed">
                {book.pages[currentPage].text}
              </p>
            </div>
          </motion.div>
        </AnimatePresence>
      </main>

      <footer className="bg-orange-300 p-4 flex justify-between items-center rounded-t-2xl z-10">
        <button onClick={prevPage} className="bg-white rounded-full p-2 shadow-md" disabled={currentPage === 0}>
          <ChevronLeft className={`w-6 h-6 ${currentPage === 0 ? 'text-gray-300' : 'text-orange-500'}`} />
        </button>
        <div className="bg-white px-4 py-2 rounded-full shadow-md">
          <span className="text-orange-500 font-bold">{currentPage + 1} / {book.pages.length}</span>
        </div>
        <button onClick={nextPage} className="bg-white rounded-full p-2 shadow-md" disabled={currentPage === book.pages.length - 1}>
          <ChevronRight className={`w-6 h-6 ${currentPage === book.pages.length - 1 ? 'text-gray-300' : 'text-orange-500'}`} />
        </button>
      </footer>
    </div>
  );
};

export default BookReader;